import { acquireTokenAsync } from '../../auth';
import {
    TagExceeedance,
    TagExceedanceState,
    TagExceedanceStatePoint,
    TagEnvelopeState,
    WorkspaceRole,
    RoleType,
    AuthType,
    StateCondition,
    TagEnvelopeDefinition,
    EnvelopeOptions,
    SuppressionRule,
    UserWorkspace,
    WorkspaceGroup, TagDetail, Attributes
} from '.';
//import { Workspace } from '../workspace-common-types';
import { buildEnvelopeData, transformEnvelopeInput } from './apollo-service-client';
import { EditEnvelopeStateProps, EditEnvelopeExpressionProps } from '../edit-envelope';
import { Envelope } from '../envelope-common-types'
import { ColourManager } from '../../components/common';
import { format } from 'date-fns';

const url = process.env.REACT_APP_SURVEILLANCE_API_URL; // "https://api-surveillance-dev.woodside.io/api/v1" //'http://localhost:5050/api/v1'

export async function createExpressionModel(workspaceName: string, calculations?: EditEnvelopeExpressionProps): Promise<string> {
    const body = JSON.stringify({
        "model_family": "Surveillance",
        "description": calculations!.description,
        "valid_from": calculations!.validFrom === undefined ? format(new Date(), 'YYYY-MM-DD') : format(calculations!.validFrom!, 'YYYY-MM-DD'),
        "execution_schedule": calculations!.schedule,
        "inputs": calculations!.inputTags,
        "expressions": [{
            "expression": calculations!.expression,
            "output": {
                "name": calculations!.name,
                "description": calculations!.description,
                "unit_of_measure": calculations!.unit_of_measure,
                "plant_name": "KGP",
                "data_type": "Float",
                "frequency": 60
            }
        }]
    });
    const requestUrl = `${url}/workspace/${workspaceName}/envelope/expression`
    const data = await fetchPost(requestUrl, body);
    return data.expressions[0].output.tag;
}

export async function putExpressionForEnvelope(workspaceName: string, tagName: string, calculations?: EditEnvelopeExpressionProps) {
    const tagSplit = tagName.split('_');
    const modelId = tagSplit[0];
    const modelVersion = isNaN(parseInt(tagSplit[1])) ? "0" : tagSplit[1];
    const body = JSON.stringify({
        "model_family": "Surveillance",
        "description": calculations!.description,
        "valid_from": calculations!.validFrom === undefined ? format(new Date(), 'YYYY-MM-DD') : format(calculations!.validFrom!, 'YYYY-MM-DD'),
        "execution_schedule": calculations!.schedule,
        "inputs": calculations!.inputTags,
        "expressions": [{
            "expression": calculations!.expression,
            "output": {
                "tag": tagName,
                "name": calculations!.name,
                "description": calculations!.description,
                "unit_of_measure": calculations!.unit_of_measure,
                "plant_name": "KGP",
                "data_type": "Float",
                "frequency": 60
            }
        }]
    });
    const requestUrl = `${url}/workspace/${workspaceName}/envelope/expression/${modelId}/${modelVersion}`
    const data = await fetchPut(requestUrl, body);
    return data;
}

export async function getExpressionForEnvelope(workspaceName: string, tagName: string): Promise<EditEnvelopeExpressionProps> {
    const tagSplit = tagName.split('_');
    const modelId = tagSplit[0];
    const modelVersion = isNaN(parseInt(tagSplit[1])) ? "0" : tagSplit[1];
    const requestUrl = `${url}/workspace/${workspaceName}/envelope/expression/${modelId}/${modelVersion}`
    const data = await fetchRequest(requestUrl);
    return {
        description: data.description,
        schedule: data.execution_schedule,
        validFrom: data.valid_from,
        expression: data.expressions[0].expression,
        name: data.expressions[0].output.name,
        inputTags: data.inputs,
        unit_of_measure: data.expressions[0].output.unit_of_measure
    };
}

export async function getUnitAndExecutionSchedule(attribute: string): Promise<Attributes[]> {
    const requestUrl = `${url}/workspace/envelope/expression/attributes`
    const data = await fetchRequest(requestUrl);
    const index = data[0].name === attribute ? 0 : 1
    const results = data[index].options
    return results.map((item: any) => {
        return { key: item.value, text: item.description };
    });
}

export async function getEnvelopes(workspaceName: string, expand: boolean): Promise<TagEnvelopeDefinition[]> {
    let requestUrl = encodeURI(`${url}/workspace/${workspaceName}/envelope?expand=${expand}`);
    return await fetchRequest(requestUrl)
        .then(function (data) {
            return data.envelopes.map((e: any) => <TagEnvelopeDefinition>{
                id: e.id,
                tag: e.tag,
                states: mapStates(e.states)
            });
        })
        .catch(function (error) {
            return error;
        });
}
export interface evaluateRequest {
    workspaceName: string,
    tags: string[],
    start_time: string,
    end_time: string,
    hide_data_points: boolean,
    apply_historic_thresholds: boolean
}

/*
 * Wraps the Surveillance API eval endpoint and mutates the result to the required TagExceedence[] Interface
*/
export async function getExecedance(workspaceName: string, tags: string[], startTime: Date, endTime: Date, hideDataPoints: boolean): Promise<TagExceeedance[]> {
    const showAllStates = true;
    let requestUrl = `${url}/eval/${workspaceName}?tags=${tags}&start_time=${startTime.toISOString()}&end_time=${endTime.toISOString()}&hide_data_points=${hideDataPoints}&apply_historic_thresholds=false`;

    const data = await fetchRequest(encodeURI(requestUrl))
    if (data instanceof Error) {
        throw data;
    }
    let results: any[] = data.map(function (d: any) {
        let states: TagExceedanceState[] = [];
        Object.keys(d.states).forEach(function (k, _) {
            const state = d.states[k];
            if (state.met || showAllStates) {
                states.push(<TagExceedanceState>{
                    id: new Date().getTime() + Math.random() * 12,
                    name: k,
                    points: getExceedancePoints(state),
                    data_count: state.data_count,
                    met_data_count: state.met_data_count,
                    met: state.met
                });
            }
        });
        return <TagExceeedance>{
            tag: d.tag,
            states: states
        };
    });
    return results;
}

export async function getWorkspaces(): Promise<any[]> {
    let requestUrl = `${url}/workspace`
    let data = await fetchRequest(requestUrl);
    let response = data.map(function (w: any) { return <any>{ name: w.name } });
    return response
}


export async function getWorkspaceGroups(workspaceName: string): Promise<WorkspaceGroup[]> {
    let requestUrl = `${url}/workspace/${encodeURI(workspaceName)}/groups`
    let data = await fetchRequest(requestUrl);
    return data.map((d: any) => {
        return {
            id: d.id,
            name: d.name,
            groups: d.groups,
            tags: d.tags.map(function (t: any) {
                return t['tag'];
            }),
            workspace: workspaceName,
            parentId: d.parentId,
            expanded: d.expanded
        }
    });
}


export async function getWorkspaceEnvelopes(workspaceName: string, requestStates: EnvelopeOptions): Promise<TagEnvelopeDefinition[]> {
    let requestUrl = `${url}/workspace/${encodeURI(workspaceName)}/envelope?expand=${requestStates.requestStates}`
    let data = await fetchRequest(requestUrl);
    let response = data.envelopes ? data.envelopes.map(function (w: any) { return { id: w.id, tag: w.tag, states: mapStates(w.states) } }) : [];
    return response
}

export async function getWorkspaceSecurity(workspaceName: string): Promise<WorkspaceRole[]> {
    let requestUrl = `${url}/security/${workspaceName}`;
    let data = await fetchRequest(requestUrl);
    let response = data.map((r: any) => {
        let role, auth = undefined;
        switch (r.type) {
            case 'User':
                role = RoleType.USER;
                break;
            case 'Group':
                role = RoleType.GROUP;
                break;
            default:
                role = r.role_type;
        }

        switch (r.auth_type) {
            case 'Owner':
                auth = AuthType.OWNER;
                break;
            case 'Creator':
                auth = AuthType.CREATOR;
                break;
            case 'Member':
                auth = AuthType.MEMBER;
                break;
            default:
                auth = r.auth_type;
        }
        return { role_type: role, auth_type: auth, principal: r.name } as WorkspaceRole;
    });
    return response
}

function convertStringToAuthType(authType: string): AuthType {

    switch (authType) {
        case 'Owner':
            return AuthType.OWNER;
            break;
        case 'Creator':
            return AuthType.CREATOR;
            break;
        case 'Member':
            return AuthType.MEMBER;
            break
        default:
            throw Error('Uknown Auth Type');
    }
}

export async function getUserSecurity(username: string): Promise<UserWorkspace[]> {
    let requestUrl = `${url}/security/user/${username}`;
    let data = await fetchRequest(requestUrl);
    const userSpaces: any[] = data.map((w: any) => {
        return {
            workspace: w.workspace,
            auth_type: convertStringToAuthType(w.auth_type),
            type: w.type
        }
    });
    const security: Record<string, UserWorkspace> = userSpaces.reduce((c: UserWorkspace[], o) => {
        const wsSec = Object.keys(c).some(k => k === o.workspace) ? c[o.workspace] : <UserWorkspace>{ workspace: o.workspace, owner: false, creator: false, member: false };
        switch (o.auth_type as AuthType) {
            case AuthType.CREATOR:
                wsSec.creator = true;
            case AuthType.OWNER:
                wsSec.owner = true;
            case AuthType.MEMBER:
                wsSec.member = true;
        }
        c[o.workspace] = { ...wsSec };
        return c;
    }, <Record<string, UserWorkspace>>{});


    const returnVal = Object.values(security);
    return returnVal;
}

export async function removeWorkspaceUser(workspaceName: string, username: string, permissionType: AuthType) {
    const authType = permissionType == AuthType.MEMBER ? "member" : "owner"
    const requestUrl = `${url}/security/${encodeURI(workspaceName)}/${authType}`
    const body = JSON.stringify({ name: username, type: "User" });
    const data = await fetchDelete(requestUrl, body);
    return data;
}

export async function addUserToWorkspace(workspaceName: string, username: string, permissionType: AuthType) {
    const authType = permissionType == AuthType.MEMBER ? "member" : "owner"
    const requestUrl = `${url}/security/${encodeURI(workspaceName)}/${authType}`
    const body = JSON.stringify({ name: username, type: "User" });
    const data = await fetchPost(requestUrl, body);
    return data;
}

export async function getHighWatersForWorkspace(workspace_name: string) {
    const requestUrl = `${url}/workspace/${encodeURI(workspace_name)}/envelope/highwater`
    const data = await fetchRequest(requestUrl);
    return data;
}

export async function getModelDetailsForWorkspace(workspace_name: string) {
    const requestUrl = `${url}/workspace/${encodeURI(workspace_name)}/envelope/details`
    const data = await fetchRequest(requestUrl);
    return data;
}

export async function getWorkspaceEnvelope(workspaceName: string, envelopeId: string): Promise<TagEnvelopeDefinition> {
    let requestUrl = `${url}/workspace/${encodeURI(workspaceName)}/envelope/${envelopeId}`;
    let data = await fetchRequest(requestUrl);
    return <TagEnvelopeDefinition>{
        id: data.id,
        tag: data.tag,
        states: mapStates(data.states),
    };
}

export async function putEnvelope(workspaceName: string, tag: string, envelopeId: string, states: EditEnvelopeStateProps[]): Promise<TagEnvelopeDefinition> {
    let requestUrl = `${url}/workspace/${encodeURI(workspaceName)}/envelope/${encodeURI(envelopeId)}`
    let envelopeData = buildEnvelopeData(tag, states);
    let data = await fetchPut(requestUrl, envelopeData);
    let response = <TagEnvelopeDefinition>{
        id: data.id,
        tag: data.tag,
        states: mapStates(data.states)
    }
    return response;
}

export async function updateEnvelope(workspaceName: string, env: TagEnvelopeDefinition): Promise<TagEnvelopeDefinition> {
    let requestUrl = `${url}/workspace/${encodeURI(workspaceName)}/envelope/${env.id}`;
    const envelopeData = JSON.stringify(env);
    let data = await fetchPut(requestUrl, envelopeData);

    let response = <TagEnvelopeDefinition>{
        id: data.id,
        tag: data.tag,
        states: mapStates(data.states)
    }
    return response;
}

export async function createEnvelope(workspaceName: string, envelope: Envelope): Promise<string> {
    let requestUrl = `${url}/workspace/${encodeURI(workspaceName)}/envelope`
    const envelopeData = transformEnvelopeInput(envelope);
    let data = await fetchPost(requestUrl, envelopeData);

    return data;
}

export async function createEnvelopeNew(workspaceName: string, tag: string, states: EditEnvelopeStateProps[]): Promise<string> {
    let requestUrl = `${url}/workspace/${encodeURI(workspaceName)}/envelope`
    const envelopeData = buildEnvelopeData(tag, states);
    let data = await fetchPost(requestUrl, envelopeData);

    return data;
}

export async function createWorkspace(workspaceName: string): Promise<string> {
    let requestUrl = `${url}/workspace`
    const requestData = JSON.stringify({ name: workspaceName });
    let data = await fetchPost(requestUrl, requestData);
    return data.name;
}

export async function renameWorkspace(workspaceName: string, newWorkspaceName: string): Promise<string> {
    let requestUrl = `${url}/workspace/${workspaceName}`
    const requestData = JSON.stringify({ name: newWorkspaceName });
    let data = await fetchPost(requestUrl, requestData);
    return data.name;
}

export async function deleteEnvelope(workspaceName: string, envelopeId: string): Promise<boolean> {
    const requestUrl = `${url}/workspace/${encodeURI(workspaceName)}/envelope/${envelopeId}`
    try {
        await fetchDelete(requestUrl);
    } catch (error) {
        return false;
    }
    return true;
}

export async function deleteWorkspace(workspaceName: string): Promise<boolean> {
    let requestUrl = `${url}/workspace/${encodeURI(workspaceName)}`
    return await fetchDelete(requestUrl).then(() => true).catch((err) => err);
}

export async function createSuppression(workspaceName: string, suppression: SuppressionRule) {
    let requestUrl = `${url}/workspace/${encodeURI(workspaceName)}/suppression`;
    const requestData = JSON.stringify({
        tags: suppression.suppressed_tags,
        start_date: suppression.start_time ? new Date(suppression.start_time).toUTCString() : new Date().toUTCString(),
        end_date: suppression.end_time ? new Date(suppression.end_time).toUTCString() : "",
        informant_tag: suppression.informant_tag ? suppression.informant_tag.tag : null,
        informant_state: suppression.informant_tag ? suppression.informant_tag.state : null,
        comment: suppression.comment
    });
    const data = await fetchPost(requestUrl, requestData);
    return data;
}

export async function updateSuppression(workspace_name: string, suppression_id: number, end_date: number) {
    let requestUrl = `${url}/workspace/${encodeURI(workspace_name)}/suppression/${suppression_id}`;
    const requestData = JSON.stringify({
        id: suppression_id,
        end_date: new Date(end_date).toUTCString(),
    });

    const data = await fetchPut(requestUrl, requestData);
    return data;
}

export async function getSuppressionsForWorkspace(workspace_name: string): Promise<SuppressionRule[]> {
    const requestUrl = `${url}/workspace/${workspace_name}/suppression`
    const data = await fetchRequest(requestUrl);
    return data.map(mapSuppression);
}

export async function getSuppressionsForEnvelope(workspace_name: string, tag: string) {
    const requestUrl = `${url}/workspace/${workspace_name}/envelope/${tag}/suppression`
    const data = await fetchRequest(requestUrl);
    return data.map(mapSuppression);
}

export async function getInformantSuppressionsForEnvelope(workspace_name: string, tag: string) {
    const requestUrl = `${url}/workspace/${workspace_name}/envelope/${tag}/suppression/informant`
    const data = await fetchRequest(requestUrl);
    return data.Map(mapSuppression);
}


export async function getTagHiWater(tags: string) {
    const requestUrl = `${url}/tag/highwater?tags=${tags}`
    const data = await fetchRequest(encodeURI(requestUrl));
    return data;
}

export async function getTagLastValue(tags: string, start_time: Date, end_time: Date) {
    const requestUrl = `${url}/tag/lastvalue?tags=${tags}&start_time=${start_time.toISOString()}&end_time=${end_time.toISOString()}`;
    const data = await fetchRequest(encodeURI(requestUrl));
    return data;
}

export async function getTagDetails(tags: string): Promise<TagDetail[]> {
    const requestUrl = `${url}/tag/details?tags=${tags}`
    const data = await fetchRequest(encodeURI(requestUrl));
    return data.map((d: any) => {
        return {
            id: d.id,
            name: d.name,
            description: d.description,
            unitOfMeasure: d.unit_of_measure,
            plantName: d.plant_name
        }
    });
}

export async function searchTags(query: string) {
    const requestUrl = `${url}/tag/search?query=${query}`
    const data = await fetchRequest(requestUrl);
    return data
}

export async function getFormulas() {
    const requestUrl = `${url}/formula`
    const data = await fetchRequest(requestUrl);
    return data
}

// export async function addWorkspaceGroup(group: WorkspaceGroup){
//     const requestUrl = `${url}/workspace/${group.workspace}/groups`
//     const requestData = JSON.stringify(group);
//     const data = await fetchPost(requestUrl, requestData);

//     return data;
// }

export async function addWorkspaceGroup(group: WorkspaceGroup) {
    const requestUrl = `${url}/workspace/${group.workspace}/groups`
    const newGroups = { ...group, tags: group.tags.map((t) => { return { Tag: t } }) };
    const requestData = JSON.stringify(newGroups);
    const data = await fetchPost(requestUrl, requestData);

    return data;
}

export async function removeWorkspaceGroup(workspace: string, groupId: number) {
    const requestUrl = `${url}/workspace/${workspace}/groups/${groupId}`
    const data = await fetchDelete(requestUrl);
    return data;
}


export async function updateWorkspaceGroup(group: WorkspaceGroup) {
    const requestUrl = `${url}/workspace/${group.workspace}/groups/${group.id}`
    const newGroups = { ...group, tags: group.tags.map((t) => { return { Tag: t } }) };
    const requestData = JSON.stringify(newGroups);
    const data = await fetchPut(requestUrl, requestData);
    return data;
}

export async function removeTagFromWorkspaceGroup(group: WorkspaceGroup, tags: string[]) {

    const requestUrl = `${url}/workspace/${group.workspace}/groups/${group.id}/tags?tags=${tags}`
    const body = JSON.stringify(tags);
    const response = await fetchDelete(requestUrl, body);
    return response;
}

export async function getWorkspaceGroupExceedence(workspace: string, group: string, start_time: Date, end_time: Date) {
    const requestUrl = `${url}/workspace/${workspace}/groups/${group}/eval?start_time${start_time}&end_time=${end_time}`;
    const data = await fetchRequest(requestUrl);
    return data
}

function mapSuppression(d: any): SuppressionRule {
    return <SuppressionRule>{
        suppressed_tags: d.suppressed_tags.map((t: any) => t.tag),
        start_time: Date.parse(d.start_date),
        end_time: d.end_date ? Date.parse(d.end_date) : null,
        informant_tag: d.informant_tag ? { tag: d.informant_tag.tag, state: d.informant_state } : undefined,
        created_by: d.created_by,
        created_date: Date.parse(d.created_date),
        modified_by: d.modified_by,
        modified_date: d.modified_date,
        identifier: d.id,
        comment: d.comment
    };
}

function mapStates(states: any[]): TagEnvelopeState[] {
    let response: TagEnvelopeState[] = [];
    Object.keys(states).forEach(function (k, _) {
        const state = states[k];
        response.push({
            name: k,
            priority: state.priority,
            conditions: mapConditions(state.conditions),
            colour: state.colour || ColourManager.StatusColours.blueGrey,
            description: state.description || "",
            short_description: state.short_description || "",
            met: false
        });
    });
    return response;
}

/// Map Conditions From States to StateCondition Interface
function mapConditions(conditions: any[]): StateCondition[] {
    return conditions.map((c: any) => {
        return <StateCondition>{
            id: c.id,
            low_threshold: c.low_threshold,
            high_threshold: c.high_threshold,
            formula_type: c.formula_type,
            formula_config: c.formula_config
        }
    });
}

/// Convert Tag State to TagExceedenceStatePoint
function getExceedancePoints(stateData: any): TagExceedanceStatePoint[] {
    if (!stateData.met) {
        return [];
    }
    //Merge the High and Low Thresholds of all conditions.
    let metConditions: any[] = stateData.met_conditions.map(function (c: any) {
        let highT = c.high_thresholds || [];
        let lowT = c.low_thresholds || [];
        return [].concat(...lowT).concat(...highT);
    });
    // Map into the TagExceedanceStatePoint Interface
    let mappedPoints = metConditions.map(function (cond: any): TagExceedanceStatePoint { return { time: cond[0], value: cond[1] } });

    return mappedPoints;
}

export async function fetchRequest(requestUrl: string): Promise<any> {
    let requestHeaders = await getAuthHeaders();
    let requestInit = <RequestInit>{
        method: 'GET',
        headers: requestHeaders,
        mode: 'cors',
        cache: 'default',
    };

    let request = new Request(requestUrl, requestInit)
    return await fetch(request)
        .then(function (response) {
            if (response.ok) {
                return response.json();
            }
            throw new Error(`${JSON.stringify(response)}`);
        }).catch((error) => {
            throw error;
        });
}


export async function fetchPut(requestUrl: string, body: string): Promise<any> {
    let requestHeaders = await getAuthHeaders();
    let requestInit = <RequestInit>{
        method: 'PUT',
        headers: requestHeaders,
        mode: 'cors',
        cache: 'default',
        body: body
    };

    let request = new Request(requestUrl, requestInit)
    return await fetch(request)
        .then(function (response) {
            if (response.ok) {
                return response.json();
            }
            throw new Error(`API Responded with: ${response.status} - ${response.statusText} `);
        }).catch((error) => {
            return error;
        });
}



export async function fetchPost(requestUrl: string, body: string): Promise<any> {
    let requestHeaders = await getAuthHeaders();
    let requestInit = <RequestInit>{
        method: 'POST',
        headers: requestHeaders,
        mode: 'cors',
        cache: 'default',
        body: body
    };

    let request = new Request(requestUrl, requestInit)
    return await fetch(request)
        .then(function (response) {
            if (response.ok) {
                return response.json();
            }
            const error = new Error(`API Responded with: ${response.status} - ${response.statusText} `);
            return Promise.reject(error);

        }).catch((error) => {
            return error;
        });
}


export async function fetchDelete(requestUrl: string, body?: string): Promise<any> {
    let requestHeaders = await getAuthHeaders();
    let requestInit = <RequestInit>{
        method: 'DELETE',
        headers: requestHeaders,
        mode: 'cors',
        cache: 'default',
    };

    if (body) { requestInit.body = body };
    let request = new Request(requestUrl, requestInit)
    return await fetch(request)
        .then(function (response) {
            if (response.ok) {
                return response.json();
            }
            throw new Error(`${JSON.stringify(response)}`);
        }).catch((error) => {
            return error;
        });
}

export async function getAuthHeaders(): Promise<any> {
    const token = await acquireTokenAsync(process.env.REACT_APP_SURVEILLANCE_RESOURCE!);
    let requestHeaders = { Authorization: `Bearer ${token}`, "Content-type": "application/json" };
    return requestHeaders;
}